<template>
    <div>
        
        <v-card
            flat
        >
            <v-card-title>
                Questionnaire Versions
            </v-card-title>

            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="currentQuestionnaireVersionList"
                    :loading="localLoading"
                    dense
                >
                
                    <!-- <template v-slot:[`item.compare`]="{item, index}">
                        <v-checkbox
                            v-model="compare"
                            dense
                            hide-details
                            class="shrink ma-0 pa-0"
                            :value="index"
                            :disabled="(compare.length > 1 && compare.indexOf(index) === -1) || !$can('read', 'forsta')"
                        >

                        </v-checkbox>
                    </template> -->

                    <!-- created at -->
                    <template #[`item.created_at`]="{item}">
                        <div class="d-flex flex-column">
                            <span class="text-capitalize text--primary">{{ dateFormat(item.created_at)}}</span>
                        </div>
                    </template>

                    <template #[`item.actions`]="{item}" >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="{}"
                                    :disabled="!$can('read', 'forsta')"
                                >
                                {{ icons.mdiWrenchOutline }}
                                </v-icon>
                            </template>
                            <span>Generate</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="warning"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    :loading="dlVersionLoading"
                                    @click="downloadQuestionnaireVersion(item.id)"
                                    :disabled="!$can('read', 'forsta')"
                                >
                                {{ icons.mdiDownloadBoxOutline }}
                                </v-icon>
                            </template>
                            <span>Download JSON</span>
                        </v-tooltip>
                        
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    color="info"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    :loading="dlWordVersionLoading"
                                    @click="downloadWordQuestionnaireVersion(item.id)"
                                    :disabled="!$can('read', 'project')"
                                >
                                {{ icons.mdiFileWordOutline }}
                                </v-icon>
                            </template>
                            <span>Download Word</span>
                        </v-tooltip>
                        
                    </template>
                    
                </v-data-table>

            </v-card-text>

        </v-card>
        <v-card flat
        v-if="$can('read', 'forsta')">
            <v-card-title>
                Compare Versions
                <v-spacer></v-spacer>
                <v-btn
                    color="info"
                    @click="diff"
                >
                    Compare
                    <v-icon
                        right
                        dark
                    >
                        {{ icons.mdiCodeJson }}
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            v-model="compareFrom"
                            :items="currentQuestionnaireVersionList"
                            item-value="id"
                            label="Compare From Version"
                            outlined
                            dense
                            full-width
                            return-object
                            >
                            <template v-slot:selection="data">
                               V{{ data.item.version }} - {{ data.item.country }}
                            </template>
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>V{{ data.item.version }} - {{ data.item.country }}</v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>

                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                        <v-autocomplete
                            v-model="compareTo"
                            :items="projectQuestionnaireVersionList"
                            item-value="id"
                            label="Compare To Version"
                            outlined
                            dense
                            full-width
                            return-object
                            >
                                <template v-slot:selection="data">
                                    V{{ data.item.version }} - {{ data.item.country }}
                                </template>
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-content>V{{ data.item.version }} - {{ data.item.country }}</v-list-item-content>
                                    </template>
                                </template>
                        </v-autocomplete>
                        
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
    
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import useSurvey from '../useSurvey'
import { deepDiffMapper } from '@core/utils'

import { 
    mdiWrenchOutline,
    mdiDownloadBoxOutline,
    mdiFileWordOutline,
    mdiCodeJson
 } from '@mdi/js'

export default ({
    props: {
        versions: {
            type:Array
        },
        loading: {
            type:Boolean
        },
        questionnaireId:{
            type:String
        }
    },
    setup(props) {

        const headers = ref([
            // {
            //     text:'',
            //     align:'left',
            //     value:'compare',
            //     sortable: false,
            //     width: '1%'
            // },
            {
                text: 'Versions',
                value: 'version',
            },
            { text: 'Date', value: 'created_at' },
            { text: 'Validated By', value: 'created_by' },
            { text: 'Decipher Path', value: 'path' },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false
            }
        ])

        
        const compareFrom = ref({})
        const compareTo = ref({})
        const compare = ref([])

        const localVersions = ref([])
        const localLoading = ref(false)

        const {
            dlVersionLoading,
            dlWordVersionLoading,
            downloadQuestionnaireVersion,
            downloadWordQuestionnaireVersion
        } = useSurvey()

        const dateFormat = (date) => {
            const d = new Date(date)
            const newDate = d.toISOString().split('T')[0];
            const time = d.toTimeString().split(' ')[0];
            return `${newDate} ${time}`
            
        }

        const diff = () => {
            const res = deepDiffMapper.map(compareFrom.value.json.pages, compareTo.value.json.pages)
            console.log(res)
        }

        const currentQuestionnaireVersionList = computed(() => {
            return localVersions.value.filter(version => version.questionnaire == props.questionnaireId)
        })

        const projectQuestionnaireVersionList = computed(() => {
            return localVersions.value.filter(version => version.id != compareFrom.value.id)
        })



        watch(
            () => props.versions,
            () => {
                localVersions.value = JSON.parse(JSON.stringify(props.versions))
            }
        )
        watch(
            () => props.loading,
            () => {
                localLoading.value = props.loading
            }
        )


        return {
            headers,
            localVersions,
            localLoading,
            dlVersionLoading,
            dlWordVersionLoading,
            downloadQuestionnaireVersion,
            downloadWordQuestionnaireVersion,
            dateFormat,
            diff,
            compare,
            compareFrom,
            compareTo,
            currentQuestionnaireVersionList,
            projectQuestionnaireVersionList,

            icons: {
                mdiWrenchOutline,
                mdiDownloadBoxOutline,
                mdiFileWordOutline,
                mdiCodeJson
            }
        }
        
    },
})
</script>
