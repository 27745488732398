<template>
  <div class="h-full">
    <alert />
    <div class="drawer-header d-flex align-center mb-4">
      <span class="font-weight-semibold text-base text--primary">Update Question - {{ localQuestion.valueName }}</span>
      <v-spacer></v-spacer>
      <!-- <v-btn
        icon
        small
        @click="$emit('update:is-question-drawer-active', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn> -->
    </div>
      <perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="ps-calendar-event-handler"
        ref="scroller"
      >
        <v-form
          ref="refQuestionHandlerForm"
          class="px-5 pt-3 pb-10"
          @submit.prevent="handleFormSubmit"
        >
          <v-card flat  v-if="localQuestion.name">
          <v-card-text class="px-0 pt-0">
              <v-text-field
                v-model="localQuestion.valueName"
                label="Label"
                outlined
                :rules="[validators.required, validators.questionLabelCheck]"
                hide-details="auto"
                dense
                class="mb-6"
              ></v-text-field>

              
              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on">
                        <editor
                          :modelValue.sync="localQuestion.html[localDefaultLang.code]"
                          :variable-suggestions="variableSuggestions"
                          label="Introduction"
                          :editor.sync="editor"
                          :rules="[validators.required]"
                          @change="onChangeDelOtherLang('html')"
                          class="pt-2"
                          v-if="localQuestion.html"
                        />
                        
                      </div>
                      
                    </template>
                    <span class="tooltip">{{getContentTooltip(localQuestion.html, content, localDefaultLang.code)}}</span>
                  </v-tooltip>
              <div v-if="localQuestion.title">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on">
                      <editor
                        :modelValue.sync="localQuestion.title[localDefaultLang.code]"
                        :variable-suggestions="variableSuggestions"
                        label="Question"
                        :editor.sync="editor"
                        @change="onChangeDelOtherLang('title')"
                        :rules="[validators.required]"
                      v-if="localQuestion.title"/>
                    </div>
                      
                  </template>
                  <span class="tooltip">{{getContentTooltip(localQuestion.title, content, localDefaultLang.code)}}</span>
                </v-tooltip>
              </div>
                <v-tooltip top v-if="localQuestion.description">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on">
                        <editor
                            :modelValue.sync="localQuestion.description[localDefaultLang.code]"
                            :variable-suggestions="variableSuggestions"
                            @change="onChangeDelOtherLang('description')"
                            label="Add. Instructions"
                            class="pb-6"
                        />
                    </div>
                      
                  </template>
                  <span class="tooltip">{{getContentTooltip(localQuestion.description, content, localDefaultLang.code)}}</span>
                </v-tooltip>

                <datatable
                  :headers="localQuestion.headers"
                  :items="localQuestion.items"
                  @save-headers="save_headers"
                  @save-items="save_items"
                  class="pb-6"
                  v-if="localQuestion.headers && localQuestion.items"
                ></datatable>
                
              <div v-if="['radiogroup','checkbox','dropdown', 'webshopping'].includes(localQuestion.type)">
                <span class="pt-4 text-subtitle-1 font-weight-bold">Choices</span>
                <v-tooltip bottom v-if="!(localQuestion.static && localQuestion.static.choices)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="openDialogBox('choices')"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiTextBoxEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Open list window</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!(localQuestion.static && localQuestion.static.choices)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="resetCodes('choices')"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiCogs }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Reset all codes/values - NOT FOR NORMS</span>
                </v-tooltip>
              <v-row  v-for="(item, i) in localQuestion.choices"
                :key="item.value" 
                class="ma-0">

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.text[localDefaultLang.code]"
                        outlined
                        dense
                        hide-details
                        class="pb-2"
                        v-bind="attrs"
                        v-on="on"
                        @change="onChangeDelOtherLang('choices', i)"
                    >
                    
                      <template slot="append">
                        <div class="d-flex justify-center align-center">
                          <span class="text-center">{{item.value}}</span>
                        </div>
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                icon 
                                x-small
                                v-bind="attrs"
                                v-on="on"
                                >
                              <v-icon>{{ icons.mdiAt }}</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                                <v-list-item
                                    v-for="(variable, suggIdx) in variableSuggestions"
                                    :key="suggIdx"
                                    @click="item.text[localDefaultLang.code] += ` {${variable.label}}`; onChangeDelOtherLang('choices', i)"
                                >
                                    <!-- <v-list-item-title></v-list-item-title> -->
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-list-item-subtitle
                                            v-bind="attrs"
                                            v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                        </template>
                                        <span>{{ variable.value }}</span>
                                    </v-tooltip>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn 
                            icon 
                            x-small
                            @click="removeItem(i, 'choices')"
                            v-if="!(localQuestion.static && localQuestion.static.choices)">
                          <v-icon
                          >{{icons.mdiTrashCanOutline}}</v-icon>
                        </v-btn>
                      </template>
                  </v-text-field>
                  </template>
                  <span class="tooltip">{{getContentTooltip(item.text, content, localDefaultLang.code)}}</span>
                </v-tooltip>
                <v-tooltip top v-if="localQuestion.choicesOrder">
                    <template v-slot:activator="{ on, attrs }">
                        <span 
                            v-bind="attrs"
                            v-on="on">
                        <v-checkbox
                            v-model="item.noShuffle"
                            hide-details
                            class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        </span>
                    </template>
                    <span>Do Not Shuffle</span>
                </v-tooltip>
                  <v-tooltip top v-if="localQuestion.type == 'checkbox'">
                      <template v-slot:activator="{ on, attrs }">
                          
                          <span 
                              v-bind="attrs"
                              v-on="on">
                          <v-checkbox
                              v-model="item.exclusive"
                              hide-details
                              class="shrink mr-2 mt-0"
                          ></v-checkbox>
                          </span>
                      </template>
                      <span>Exclusive</span>
                  </v-tooltip>
                  
                  <v-tooltip top v-if="localQuestion.type != 'dropdown'">
                      <template v-slot:activator="{ on, attrs }">
                          <span 
                              v-bind="attrs"
                              v-on="on">
                          <v-checkbox
                              v-model="item.showOtherItem"
                              hide-details
                              class="shrink mr-2 mt-0"
                          ></v-checkbox>
                          </span>
                      </template>
                      <span>Show Other (Specify)</span>
                  </v-tooltip>
            </v-row>
              
                <v-text-field
                  label="Add item"
                  hide-details
                  outlined
                  dense
                  v-model="newChoiceItem"
                  :append-icon="icons.mdiPlus"
                  @keydown.enter.prevent="addItem(newChoiceItem, 'choices')"
                  @click:append="addItem(newChoiceItem, 'choices')"
                  v-if="!(localQuestion.static && localQuestion.static.choices)"
              >
              </v-text-field>
            </div>
            <div v-if="['radiogroup', 'dropdown', 'checkbox'].includes(localQuestion.type)">
              <v-autocomplete
                v-model="localQuestion.choicesFromQuestion"
                :items="questionsWithChoices"
                label="Copy choices from the following question"
                hide-detail
                dense
                outlined
                clearable
                placeholder="Select question"
                class="pt-6"
                @change="updateCopyChoice"
              >

              </v-autocomplete>

              <v-radio-group
                v-model="localQuestion.choicesFromQuestionMode"
                label="Which choices to copy"
                row
                class="mt-0"
                v-if="localQuestion.choicesFromQuestion"
                :rules="[validators.requiredArray]"
              >
                    <v-radio
                      label="All"
                      value="all"
                    >
                    </v-radio>
                    <v-radio
                      label="Selected"
                      value="selected"
                    ></v-radio>
                    <v-radio
                      label="Unselected"
                      value="unselected"
                    ></v-radio>
              </v-radio-group>

            </div>

            
            <div v-if="localQuestion.columns">
              <span class="pt-4 text-subtitle-1 font-weight-bold">Columns</span>
              <v-tooltip bottom v-if="!(localQuestion.static && localQuestion.static.columns)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="openDialogBox('columns')"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiTextBoxEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Open list window</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!(localQuestion.static && localQuestion.static.columns)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="resetCodes('columns')"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiCogs }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Reset all codes/values - NOT FOR NORMS</span>
                </v-tooltip>
                <v-tooltip bottom
                      v-for="(item, i) in localQuestion.columns" 
                      :key="item.value || item.name">
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="item.text[localDefaultLang.code]"
                      outlined
                      dense
                      hide-details
                      class="pb-2"
                      v-bind="attrs"
                      v-on="on"
                      @change="onChangeDelOtherLang('columns', i)"
                      v-if="item.text"
                  >
                  
                  <template slot="append">

                        <div class="d-flex justify-center align-center">
                          <span class="text-center">{{item.value}}</span>
                        </div>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            >
                          <v-icon>{{ icons.mdiAt }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item
                                v-for="(variable, suggIdx) in variableSuggestions"
                                :key="suggIdx"
                                @click="item.text[localDefaultLang.code] += ` {${variable.label}}`; onChangeDelOtherLang('columns', i)"
                            >
                                <!-- <v-list-item-title></v-list-item-title> -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle
                                        v-bind="attrs"
                                        v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                    </template>
                                    <span>{{ variable.value }}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn 
                        icon 
                        x-small
                        @click="removeItem(i, 'columns')"
                        v-if="!(localQuestion.static && localQuestion.static.columns)">
                      <v-icon
                      >{{icons.mdiTrashCanOutline}}</v-icon>
                    </v-btn>
                    </template>
                
                
                </v-text-field>
                  <v-text-field
                      v-model="item.title[localDefaultLang.code]"
                      outlined
                      dense
                      hide-details
                      class="pb-2"
                      v-bind="attrs"
                      v-on="on"
                      v-else
                  >
                
                  <template slot="append">

                        
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            >
                          <v-icon>{{ icons.mdiAt }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item
                                v-for="(variable, suggIdx) in variableSuggestions"
                                :key="suggIdx"
                                @click="item.title[localDefaultLang.code] += ` {${variable.label}}`"
                            >
                                <!-- <v-list-item-title></v-list-item-title> -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle
                                        v-bind="attrs"
                                        v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                    </template>
                                    <span>{{ variable.value }}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn 
                        icon 
                        x-small
                        @click="removeItem(i, 'columns')"
                  v-if="!(localQuestion.static && localQuestion.static.columns)">
                      <v-icon
                      >{{icons.mdiTrashCanOutline}}</v-icon>
                    </v-btn>
                    </template>
                </v-text-field>
                </template>
                <span class="tooltip">{{getContentTooltip(item.text || item.title, content, localDefaultLang.code)}}</span>
              </v-tooltip>
                <v-text-field
                  label="Add item"
                  hide-details
                  outlined
                  dense
                  v-model="newColumnItem"
                  :append-icon="icons.mdiPlus"
                  @keydown.enter.prevent="addItem(newColumnItem, 'columns')"
                  @click:append="addItem(newColumnItem, 'columns')"
                  class="pb-6"
                  v-if="!(localQuestion.static && localQuestion.static.columns)"
              >
              </v-text-field>
            </div>

            <!----->
            <div v-if="localQuestion.themes">
              <span class="pt-4 text-subtitle-1 font-weight-bold">Themes</span>
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="addTheme"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiPlus }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Add new theme</span>
                </v-tooltip>
              <div v-for="(theme, themeIndex) in localQuestion.themes"
                :key="themeIndex"
              >
              <v-text-field
                v-model="theme.title[localDefaultLang.code]"
                label="Label"
                outlined
                :rules="[validators.required, validators.questionLabelCheck]"
                hide-details="auto"
                dense
                class="py-2"
                @change="onChangeDelOtherLang('themes', themeIndex)"
              >
                
              <template slot="append">

                        
                <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn 
                        icon 
                        x-small
                        v-bind="attrs"
                        v-on="on"
                        >
                      <v-icon>{{ icons.mdiAt }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item
                            v-for="(variable, suggIdx) in variableSuggestions"
                            :key="suggIdx"
                            @click="theme.title[localDefaultLang.code] += ` {${variable.label}}`; onChangeDelOtherLang('themes', themeIndex)"
                        >
                            <!-- <v-list-item-title></v-list-item-title> -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle
                                        v-bind="attrs"
                                        v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                    </template>
                                    <span>{{ variable.value }}</span>
                                </v-tooltip>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-btn 
                    icon 
                    x-small
                    @click="removeItem(themeIndex, 'themes')">
                  <v-icon
                  >{{icons.mdiTrashCanOutline}}</v-icon>
                </v-btn>
              </template>
            
            </v-text-field>

              <v-tooltip bottom v-if="!(theme.static && theme.static.rows)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="openDialogBox('rows', {name: 'themes', index: themeIndex})"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiTextBoxEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Open list window</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!(theme.static && theme.static.rows)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="resetCodes('rows', {name: 'themes', index: themeIndex})"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiCogs }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Reset all codes/values - NOT FOR NORMS</span>
                </v-tooltip>
                <div
                      v-for="(item, i) in theme.rows" 
                      :key="item.value || item.name">
                     
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="item.text[localDefaultLang.code]"
                      outlined
                      dense
                      hide-details
                      class="pb-2"
                      v-bind="attrs"
                      v-on="on"
                      @change="onChangeDelOtherLang('themes', themeIndex, 'rows', i)"
                      v-if="item.text"
                  >
                  <template slot="append">

                    <div class="d-flex justify-center align-center">
                      <span class="text-center">{{item.value}}</span>
                    </div>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            >
                          <v-icon>{{ icons.mdiAt }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item
                                v-for="(variable, suggIdx) in variableSuggestions"
                                :key="suggIdx"
                                @click="item.text[localDefaultLang.code] += ` {${variable.label}}`; onChangeDelOtherLang('themes', themeIndex, 'rows', i)"
                            >
                                <!-- <v-list-item-title></v-list-item-title> -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle
                                        v-bind="attrs"
                                        v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                    </template>
                                    <span>{{ variable.value }}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn 
                        icon 
                        x-small
                        @click="removeItem(i, 'rows', {name: 'themes', index: themeIndex})"
                        v-if="!(theme.static && theme.static.rows)">
                      <v-icon
                      >{{icons.mdiTrashCanOutline}}</v-icon>
                    </v-btn>
                    </template>
                
                
                </v-text-field>
                  <v-text-field
                      v-model="item.title[localDefaultLang.code]"
                      outlined
                      dense
                      hide-details
                      class="pb-2"
                      v-bind="attrs"
                      v-on="on"
                      v-else
                  >
                
                  
                  <template slot="append">

                        
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            >
                          <v-icon>{{ icons.mdiAt }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item
                                v-for="(variable, suggIdx) in variableSuggestions"
                                :key="suggIdx"
                                @click="item.title[localDefaultLang.code] += ` {${variable.label}}`"
                            >
                                <!-- <v-list-item-title></v-list-item-title> -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle
                                        v-bind="attrs"
                                        v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                    </template>
                                    <span>{{ variable.value }}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn 
                        icon 
                        x-small
                        @click="removeItem(i, 'rows', {name: 'themes', index: themeIndex})"
                        v-if="!(theme.static && theme.static.rows)">
                      <v-icon
                      >{{icons.mdiTrashCanOutline}}</v-icon>
                    </v-btn>
                    </template>
                
                </v-text-field>
                </template>
                <span class="tooltip">code: {{ item.value }} {{getContentTooltip(item.text || item.title, content, localDefaultLang.code)}}</span>
              </v-tooltip>

                </div>
                
                <v-text-field
                  label="Add item"
                  hide-details
                  outlined
                  dense
                  v-model="newThemeRowItem[themeIndex]"
                  :append-icon="icons.mdiPlus"
                  @keydown.enter.prevent="addItem(newThemeRowItem[themeIndex], 'rows', {name: 'themes', index: themeIndex})"
                  @click:append="addItem(newThemeRowItem[themeIndex], 'rows', {name: 'themes', index: themeIndex})"
                  class="pb-2"
                  v-if="!(theme.static && theme.static.rows)"
              >
              </v-text-field>
              <hr class="my-2"/>
            </div>
            </div>


            <!----->
            
            <div v-if="localQuestion.rows">
              <span class="pt-4 text-subtitle-1 font-weight-bold">Rows</span>
              <v-tooltip bottom v-if="!(localQuestion.static && localQuestion.static.rows)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="openDialogBox('rows')"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiTextBoxEditOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Open list window</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!(localQuestion.static && localQuestion.static.rows)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        text
                        icon
                        x-small
                        color="primary"
                        @click="resetCodes('rows')"
                        v-bind="attrs"
                        v-on="on"
                    >
                    <v-icon 
                        color="primary"
                        small
                    >{{ icons.mdiCogs }}</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip">Reset all codes/values - NOT FOR NORMS</span>
                </v-tooltip>
                <v-row v-for="(item, i) in localQuestion.rows" 
                      :key="item.value || item.name"
                    class="ma-0">
                      <v-tooltip bottom
                      >
                  <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :key="item.value"
                      v-model="item.text[localDefaultLang.code]"
                      outlined
                      dense
                      hide-details
                      class="pb-2"
                      v-bind="attrs"
                      v-on="on"
                      @change="onChangeDelOtherLang('rows', i)"
                  >
                    
                  <template slot="append">

                        

                    <div class="d-flex justify-center align-center">
                        <span class="text-center">{{item.value}}</span>
                      </div>
                    <v-menu>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn 
                            icon 
                            x-small
                            v-bind="attrs"
                            v-on="on"
                            >
                          <v-icon>{{ icons.mdiAt }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                            <v-list-item
                                v-for="(variable, suggIdx) in variableSuggestions"
                                :key="suggIdx"
                                @click="item.text[localDefaultLang.code] += ` {${variable.label}}`; onChangeDelOtherLang('rows', i)"
                            >
                                <!-- <v-list-item-title></v-list-item-title> -->
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item-subtitle
                                        v-bind="attrs"
                                        v-on="on">{{ variable.label }}</v-list-item-subtitle>
                                    </template>
                                    <span>{{ variable.value }}</span>
                                </v-tooltip>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn 
                        icon 
                        x-small
                        @click="removeItem(i, 'rows')"
                        v-if="!(localQuestion.static && localQuestion.static.rows)">
                      <v-icon
                      >{{icons.mdiTrashCanOutline}}</v-icon>
                    </v-btn>
                    </template>
                
                  </v-text-field>
                  </template>
                  <span class="tooltip">{{getContentTooltip(item.text || item.title, content, localDefaultLang.code)}}</span>
                </v-tooltip>

                  
                <v-tooltip top v-if="localQuestion.choicesOrder">
                    <template v-slot:activator="{ on, attrs }">
                        <span 
                            v-bind="attrs"
                            v-on="on">
                        <v-checkbox
                            v-model="item.noShuffle"
                            hide-details
                            class="shrink mr-2 mt-0"
                        ></v-checkbox>
                        </span>
                    </template>
                    <span>Do Not Shuffle</span>
                </v-tooltip>
                  
                </v-row>
                
                <v-text-field
                  label="Add item"
                  outlined
                  dense
                  v-model="newRowItem"
                  :append-icon="icons.mdiPlus"
                  @keydown.enter.prevent="addItem(newRowItem, 'rows')"
                  @click:append="addItem(newRowItem, 'rows')"
                  v-if="!(localQuestion.static && localQuestion.static.rows)"
              >
              </v-text-field>
            </div>

            
            <div 
                v-if="localQuestion.inputType == 'number'">
              <span class="pt-4 text-subtitle-1 font-weight-bold">Numeric Options</span>
                <v-text-field
                    v-model="localQuestion.min"
                    label="Min"
                    type="number"
                    hide-details
                    outlined
                    dense
                    class="pt-2"
                >
                </v-text-field>
                
                <v-text-field
                    v-model="localQuestion.max"
                    label="Max"
                    type="number" 
                    hide-details
                    outlined
                    dense
                    class="py-4"
                >
                </v-text-field>
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    class="d-flex"
                  >
                    <v-switch
                      v-model="localQuestion.decimal"
                      color="primary"
                      dense
                      :label="`Decimal`"
                      class="mt-0"
                  ></v-switch>
                  
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    class="d-flex"
                  >

                    <v-text-field
                      v-model="localQuestion.currency"
                      label="Currency"
                      hide-details
                      outlined
                      dense
                      class="align-start"
                  >
                  </v-text-field>
                  </v-col>
                </v-row>
                

          </div>
            
            <div v-if="['checkbox', 'dropdown', 'radiogroup', 'matrix', 'communication'].includes(localQuestion.type)">
              <span class="pt-4 text-subtitle-1 font-weight-bold">Other Options</span>
              <v-select
                v-model="localQuestion.choicesOrder"
                :items="choicesOrder"
                label="Choices Sort Order"
                dense
                outlined
              ></v-select>
              
            </div>
            <div v-if="localQuestion.type === 'checkbox'">
              <v-text-field
                  v-model="localQuestion.maxSelectedChoices"
                  label="Maximum Selected Choices"
                  outlined
                  dense
                  max
                  type="number"
                  :rules="[validators.between(localQuestion.maxSelectedChoices, 0, choicesUpperLimit)]"
              >
              </v-text-field>
            </div>
            <div v-if="localQuestion.duration || localQuestion.flag">
              <span class="pt-2 text-subtitle-1 font-weight-bold">Parameters:</span>
              <v-text-field
                  v-model="localQuestion.duration"
                  :label="`Duration in ms`"
                  type="number"
                  hide-details
                  outlined
                  dense
                  class="pb-4"
                  v-if="localQuestion.duration"
              >
              </v-text-field>
              <v-radio-group
                  v-model="localQuestion.flag"
                  row
                  v-if="localQuestion.flag"
              >
              <v-radio
                  label="Shelf"
                  value="shelf"
              ></v-radio>
              <v-radio
                  label="Pack"
                  value="pack"
              ></v-radio>
              </v-radio-group>
              
            </div>
            <span class="pt-2 text-subtitle-1 font-weight-bold">Instructions:</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                    <editor
                      :modelValue.sync="localQuestion.notes[localDefaultLang.code]"
                      :variable-suggestions="variableSuggestions"
                      @change="onChangeDelOtherLang('notes')"
                      label="Prog. instructions"
                      class="pt-2"
                      v-if="localQuestion.notes"
                    />
                </div>
                  
              </template>
              <span class="tooltip">{{getContentTooltip(localQuestion.notes, content, localDefaultLang.code)}}</span>
            </v-tooltip>
          </v-card-text>
            <v-card-actions class="pt-4">
              
              <v-btn
                color="primary"
                class="me-3"
                type="submit"

              >
              Update
              </v-btn>
              <v-btn
                outlined
                @click="resetEventLocal"
              >
                Reset
              </v-btn>
            </v-card-actions>
          </v-card>
            </v-form>
      </perfect-scrollbar>
      
      <list-item-dialog
          :isDialogOpen.sync="isDialogOpen"
          :title="titleDialog"
          :itemList="itemList"
          :state="stateDialog"
          :defaultLang="localDefaultLang"
          @close="isDialogOpen=false"
          @update-list="updateItemList"
          v-if="localDefaultLang"
      >

      </list-item-dialog>

  </div>
  
</template>

<script>
import { ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import store from '@/store'
import useSurvey from '../useSurvey'
import { required, urlValidator, emailValidator, requiredArray, between } from '@core/utils/validation'
import { 
  mdiTrashCanOutline,
  mdiClose,
  mdiPlus,
  mdiTextBoxEditOutline,
  mdiCogs,
  mdiAt,
  mdiDotsVertical } from '@mdi/js'
import ListItemDialog from './ListItemDialog.vue'
import Alert from '@/views/components/alert/Alert.vue'
// import the component and the necessary extensions
import Editor from '@/views/components/editor/Editor.vue'
import Datatable from '@/views/components/datatable/Datatable.vue'


export default {
  components: {
    Alert,
    ListItemDialog,
    PerfectScrollbar,
    Editor,
    Datatable
  },
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    question: {
      type: Object,
      required: true,
    },
    content: {
      type: Object,
      required: false,
      default: () => {}
    },
    clearQuestionData: {
      type: Function,
      default: () => {}
    },
    defaultLang: {
      type: Object,
      required: true
    },
    questionLabelList: {
      type: Set,
      default: () => new Set()
    },
    questionsWithChoices: {
      type: Array,
      default: () => []
    },
    variableSuggestions: {
        type: Array,
        default: () => []
    }
  },
  setup(props, { emit }) {
    // ————————————————————————————————————
    //* ——— Template Refs
    // ————————————————————————————————————

    const vm = getCurrentInstance().proxy
    const refQuestionHandlerForm = ref(null)
    
    store.commit('comp-alert/SET_MESSAGE', {})

    // ————————————————————————————————————
    //* ——— Local Event
    // ————————————————————————————————————
    const newChoiceItem = ref("")
    const newColumnItem = ref("")
    const newRowItem = ref("")
    const newThemeRowItem = ref(["", "", ""])
    const editor = ref({})
    const changes = ref({})
    

    const {
      choicesOrder,
      getContentTooltip,
      isObjectEmpty
    } = useSurvey()

    

    const localQuestion = ref(JSON.parse(JSON.stringify(props.question)))
    const localDefaultLang = ref({})
    localDefaultLang.value = JSON.parse(JSON.stringify(props.defaultLang))

    const resetEventLocal = () => {
      localQuestion.value = JSON.parse(JSON.stringify(props.question))
      changes.value = {}
      newChoiceItem.value = ""
      newColumnItem.value = ""
      newRowItem.value = ""
      newThemeRowItem.value = ["", "", ""]
      // Reset Validation
      refQuestionHandlerForm.value.resetValidation()
    }


    const isDialogOpen = ref(false)
    const titleDialog = ref("")
    const itemList = ref("")
    const stateDialog = ref("")
    const levelDialog = ref(null)
    
    
    const questionLabelCheck = (label) => {
            
        return [...props.questionLabelList].filter(element => element == label).length == 0 || "Question Label already exist"
    }
    
    const removeItem = (index, localList, level=null) => {
      if (level !== null) {
          const list = localQuestion.value[level.name][level.index][localList]
          const newList = [
              ...list.slice(0, index),
              ...list.slice(index + 1, list.length)
          ]
          localQuestion.value[level.name][level.index][localList] = newList
      
      } else {
        const list = localQuestion.value[localList]
        const newList = [
            ...list.slice(0, index),
            ...list.slice(index + 1, list.length)
        ]
        
        localQuestion.value[localList] = newList
      }
    }
    const addItem = (newVal, localList, level=null) => {
      
      if (newVal) {

        if (level !== null) {
          const list = localQuestion.value[level.name][level.index][localList]
          
          let codeList = {}
          list.forEach(element => {
              codeList[element.value] = 0
          })

          let dupCode = false
          let index = 1
          let code = ''

          while(!dupCode){
              code = `${localList === 'columns' ? 'c' : 'r'}${index}`
              if (codeList[code] === undefined){
                  codeList[code] = 1
                  dupCode = true
              } else {
                  index++
                  code =  `${localList === 'columns' ? 'c' : 'r'}${index}`
              }
          }


          const newItem = {
              value: code,
              text: {
                [localDefaultLang.value.code]: newVal
              }
          }
          const newList = [...list, newItem]
          newChoiceItem.value = ""
          newColumnItem.value = ""
          newRowItem.value = ""
          newThemeRowItem.value = ["", "", ""]
          localQuestion.value[level.name][level.index][localList] = newList
        } else {
          const list = localQuestion.value[localList]
          
          let codeList = {}
          list.forEach(element => {
              codeList[element.value] = 0
          })

          let dupCode = false
          let index = 1
          let code = ''

          while(!dupCode){
              code = `${localList === 'columns' ? 'c' : 'r'}${index}`
              if (codeList[code] === undefined){
                  codeList[code] = 1
                  dupCode = true
              } else {
                  index++
                  code =  `${localList === 'columns' ? 'c' : 'r'}${index}`
              }
          }


          const newItem = {
              value: code,
              text: {
                [localDefaultLang.value.code]: newVal
              }
          }
          const newList = [...list, newItem]
          newChoiceItem.value = ""
          newColumnItem.value = ""
          newRowItem.value = ""
          newThemeRowItem.value = ["","",""]
          localQuestion.value[localList] = newList
        }
      }
    }

    const addTheme = () => {
      newThemeRowItem.value.push("")
      localQuestion.value.themes.push({
            "title":{[`${localDefaultLang.value.code}`]:"This ..."},
            "rows": [
                {
                    "text": {
                        [`${localDefaultLang.value.code}`]: "..."
                    },
                    "value": "r1"
                }
            ]
      })
    }

    const openDialogBox = (localList, level=null) => {

      isDialogOpen.value = true
      titleDialog.value = "Please list the items.\nOne item per line."
      stateDialog.value = localList
      levelDialog.value = level
      
      let list = {}
      if (level !== null){

        list = localQuestion.value[level.name][level.index][localList].map((e) => {
            return `${e.value}|${e.text[localDefaultLang.value.code]}`
        })
      } else {
        list = localQuestion.value[localList].map((e) => {
            return `${e.value}|${e.text[localDefaultLang.value.code]}`
        })

      }
        itemList.value = list.join('\r\n')
    }

    
    const updateItemList = (itemList) => {
      
      itemList.map((item) => {
        if (levelDialog.value !== null) {
          
          localQuestion.value[levelDialog.value.name][levelDialog.value.index][stateDialog.value].map((choice) => {
            if (choice.value === item.value) {
              if (choice.text[localDefaultLang.value.code] !== item.text) {
                let answer = {}
                answer[localDefaultLang.value.code] = item.text[localDefaultLang.value.code]
                item.text = answer
              } else {
                item.text = choice.text
              }
            }
          })
        } else {
          localQuestion.value[stateDialog.value].map((choice) => {
            if (choice.value === item.value) {
              if (choice.text[localDefaultLang.value.code] !== item.text) {
                let answer = {}
                answer[localDefaultLang.value.code] = item.text[localDefaultLang.value.code]
                item.text = answer
              } else {
                item.text = choice.text
              }
            }
          })
        }
      })
      if (levelDialog.value !== null) {
        
        localQuestion.value[levelDialog.value.name][levelDialog.value.index][stateDialog.value] = itemList
      } else {
        localQuestion.value[stateDialog.value] = itemList
      }
    }

    const choicesUpperLimit = computed(() => {
      return localQuestion.value.choices.length
    })

    const updateCopyChoice = () => {
      localQuestion.value.choicesFromQuestionMode = {}
      localQuestion.value.choices = []
    }

    const resetCodes = (localList, level=null) => {
      let index = 1
      if(level !== null){

        localQuestion.value[level.name][level.index][localList].map(element => {
          element.value = localList == 'columns' ? `c${index}` : `r${index}`
          index++
        })
      } else {

        localQuestion.value[localList].map(element => {
          element.value = localList == 'columns' ? `c${index}` : `r${index}`
          index++
        })
      }
    }

    const onChangeDelOtherLang = (element, index=null, subElement=null, subIndex=null) => {
      if (index === null) changes.value[element] = index
      else {
        if (subElement != null && subIndex != null) {
          if (changes.value[`${element}.${subElement}`] == undefined) changes.value[`${element}.${subElement}`] = {}
            if (changes.value[`${element}.${subElement}`][index] == undefined) changes.value[`${element}.${subElement}`][index] = new Set()
            changes.value[`${element}.${subElement}`][index].add(subIndex)
        }
        else {
          if (changes.value[element] == undefined) changes.value[element] =  new Set();
          changes.value[element].add(index)
        }
      }
    }

    

    const save_headers = (headers) => {
      localQuestion.value.headers = headers
    }
    const save_items = (items) => {
      localQuestion.value.items = items
    }

    watch(
      () => props.question,
      () => {
        resetEventLocal()
      },
      { deep: true },
    )

    watch(
      () => props.defaultLang,
      () => {
        localDefaultLang.value = JSON.parse(JSON.stringify(props.defaultLang))
      }
    )

    watch(
      () => props.isActive,
      (newVal, oldVal) => {
        if (oldVal) {
          vm.$refs.scroller.$el.scrollTop = 0;
          vm.$refs.scroller.update()
        }
      }
    )

  

    // ————————————————————————————————————
    //* ——— Form
    // ————————————————————————————————————

    const handleFormSubmit = () => {
      
      const isEditorValid = isObjectEmpty(editor.value) || editor.value === null ? true : editor.value.isValid()
      const isFormValid = refQuestionHandlerForm.value.validate()
      if (!(isFormValid && isEditorValid)) return

      const question = JSON.parse(JSON.stringify(localQuestion.value))


      Object.entries(changes.value).forEach(([key, value]) => {
        console.log(key, value)
        if (key == 'themes') {
          value.forEach(idx =>{
            for (const lang in question[key][idx].title) {
              if (![localDefaultLang.value.code].includes(lang)) {
                delete question[key][idx].title[lang];
              }
            }
          })
          
        }
        else if (key == 'themes.rows') {
          Object.entries(value).forEach(([themeIdx, rowsIndexes]) => {
            rowsIndexes.forEach(idx => {
              for (const lang in question['themes'][themeIdx].rows[idx].text) {
                if (![localDefaultLang.value.code].includes(lang)) {
                  delete question['themes'][themeIdx].rows[idx].text[lang];
                }
              }
            })
          })
        }
        else if (['columns', 'rows', 'choices'].includes(key)) {
          value.forEach(idx =>{
            for (const lang in question[key][idx].text) {
              if (![localDefaultLang.value.code].includes(lang)) {
                delete question[key][idx].text[lang];
              }
            }
          })
        }
        else {
          for (const lang in question[key]) {
              if (![localDefaultLang.value.code].includes(lang)) {
                delete question[key][lang];
              }
            }
        }
      })

      // // * If event has id => Edit Event
      // // Emit event for add/update event
      if (question.valueName) emit('update-item', question)

    }

    // ————————————————————————————————————
    //* ——— Perfect Scrollbar
    // ————————————————————————————————————

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      wheelSpeed: 0.7,
    }


    return {
      
      // Template Refs
      refQuestionHandlerForm,

      // Local Event
      localQuestion,
      editor,
      resetEventLocal,
      newChoiceItem,
      newColumnItem,
      newRowItem,
      newThemeRowItem,
      removeItem,
      addItem,
      addTheme,
      localDefaultLang,
      save_headers,
      save_items,

      isDialogOpen,
      titleDialog,
      openDialogBox,
      resetCodes,
      itemList,
      stateDialog,
      updateItemList,
      choicesUpperLimit,
      choicesOrder,
      updateCopyChoice,
      getContentTooltip,
      onChangeDelOtherLang,

      // Form
      handleFormSubmit,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      // Field Validators
      validators: {
        required,
        urlValidator,
        emailValidator,
        requiredArray,
        between,
        questionLabelCheck
      },

      // Icons
      icons: {
        mdiTrashCanOutline,
        mdiClose,
        mdiPlus,
        mdiTextBoxEditOutline,
        mdiCogs,
        mdiAt,
        mdiDotsVertical
      },

    }
  },
}
</script>

<style lang="scss">
.ps-calendar-event-handler {
  height: calc(100% - 44px - 24px - 2px);

  // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
  // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
  .select-guest {
    .v-select__selections {
      .v-chip--select {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }
  }
}
.v-navigation-drawer--overlay {
  
  transform: translateX(100%); 
  z-index: 7;
}
.v-tooltip__content { 
  .tooltip{
    white-space: pre-wrap; 
  }
}
.tiptap-vuetify-editor__content {
  padding: 12px;
  .ProseMirror-focused {
      outline: none;
  }

}

</style>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.ProseMirror >>> p {
      margin-bottom:0px !important;
    }

</style>
