<template>
    <div class="pb-6">
        <label class="v-label v-label--active pt-2" 
            style="left: 0px;right: auto;position: absolute;transform: translateY(-16px) scale(0.75);">
            {{ label }}
        </label>
        <tiptap-vuetify
            ref="inputRef"
            v-model="valueInner"
            placeholder="Write something …"
            :extensions="extensions"
            :native-extensions="nativeExtensions"
            :card-props="{ flat: false, outlined:true, style:borderStyle}"
            :toolbar-attributes="{ color: 'black', dark: true }"
            @init="onInit"
            @keydown="$emit('change')"
            class="pr-2 pt-2"
        >  
            <template #toolbar="{ commands, isActive }">
                <header 
                    class="v-sheet theme--dark v-toolbar v-toolbar--dense v-toolbar--flat black"
                >
                    <div class="v-toolbar__content">
                        <div>
                            <v-btn
                                small
                                text
                                icon
                                title="Underline"
                                @click="commands.underline"
                                class="theme--dark"
                                :class="isActive.underline() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{ icons.mdiFormatUnderline }}</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                text
                                icon
                                title="Strike"
                                @click="commands.strike"
                                class="theme--dark"
                                :class="isActive.strike() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{ icons.mdiFormatStrikethrough }}</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                text
                                icon
                                title="Bold"
                                @click="commands.bold"
                                class="theme--dark"
                                :class="isActive.bold() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{icons.mdiFormatBold}}</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                text
                                icon
                                title="Italic"
                                @click="commands.italic"
                                class="theme--dark"
                                :class="isActive.italic() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{icons.mdiFormatItalic}}</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                text
                                icon
                                title="Interviewer Instructions"
                                @click="commands.interviewer"
                                class="theme--dark"
                                :class="isActive.interviewer() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{icons.mdiAccountVoice}}</v-icon>
                            </v-btn>
                            
                            <v-btn
                                small
                                text
                                icon
                                title="Bullet List"
                                @click="commands.bullet_list"
                                class="theme--dark"
                                :class="isActive.bullet_list() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{icons.mdiFormatListBulleted}}</v-icon>
                            </v-btn>
                            
                            <!-- <v-btn
                                small
                                text
                                icon
                                title="Variable"
                                @click="commands.mention({id:1, label:'testProduct'})"
                                class="theme--dark"
                            >
                                <v-icon>{{icons.mdiAt}}</v-icon>
                            </v-btn> -->
                            <v-menu>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        small
                                        text
                                        icon
                                        title="Variable"
                                        class="theme--dark"
                                        v-bind="attrs"
                                        v-on="on"
                                        :class="isActive.variable() ? 'v-btn--active' : ''"
                                    >
                                        <v-icon>{{icons.mdiAt}}</v-icon>
                                    </v-btn>
                                </template>

                                <v-list dense>
                                    <v-list-item
                                        v-for="(item, i) in variableSuggestions"
                                        :key="i"
                                        @click="commands.variable(item); $emit('change')"
                                    >
                                        <!-- <v-list-item-title></v-list-item-title> -->
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item-subtitle
                                                v-bind="attrs"
                                                v-on="on">{{ item.label }}</v-list-item-subtitle>
                                            </template>
                                            <span>{{ item.value }}</span>
                                        </v-tooltip>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-btn
                                small
                                text
                                icon
                                title="Horizontal Rule"
                                @click="commands.horizontal_rule"
                                class="theme--dark"
                                :class="isActive.horizontal_rule() ? 'v-btn--active' : ''"
                            >
                                <v-icon>{{icons.mdiMinus}}</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                text
                                icon
                                title="Undo"
                                @click="commands.undo"
                                class="theme--dark"
                            >
                                <v-icon>{{icons.mdiUndo}}</v-icon>
                            </v-btn>
                            <v-btn
                                small
                                text
                                icon
                                title="Redo"
                                @click="commands.redo"
                                class="theme--dark"
                            >
                                <v-icon>{{icons.mdiRedo}}</v-icon>
                            </v-btn>

                        </div>
                    
                    </div>
                </header>
                
            </template>
        </tiptap-vuetify>
        <div class="ml-4 text-xs v-messages theme--light error--text" v-if="!checker">
            <span class="text--red">This field is required</span>
        </div>
    </div>
</template>

<script>
import { ref, watch, defineComponent } from '@vue/composition-api'
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, BulletList, OrderedList, ListItem, Code, HorizontalRule, History } from 'tiptap-vuetify'
import { Extension } from 'tiptap'
import { HardBreak,  } from 'tiptap-extensions'
import Interviewer from './marks/Interviewer'
import Variable from './nodes/variable/Variable'

import { 
    mdiFormatUnderline,
    mdiFormatStrikethrough,
    mdiFormatBold,
    mdiFormatItalic,
    mdiMinus,
    mdiUndo,
    mdiRedo,
    mdiAccountVoice,
    mdiFormatListBulleted,
    mdiFormatListNumbered,
    mdiAt,
} from '@mdi/js'

export default defineComponent({
    components: {
        TiptapVuetify,
    },
    props: {
        modelValue: {
            type: [String, Number],
            default: ''
        },
        label: {
            type: String,
            default: () => ''
        },
        placeholder: {
            type: String,
            default: () => 'write something...'
        },
        rules:{
            type:Array,
            default: () => [true]
        },
        editor: {
            type: Object,
            default: () => {}
        },
        variableSuggestions: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit }) {
        
        const editor = ref({})
        const inputRef = ref(null)
        const valueInner = ref(props.modelValue)
        const isEmpty = ref(false)
        const borderStyle = ref('')
        const rules = ref([])
        const checker = ref(true)
        const init = ref(true)

        const onInit = (e) => {
            editor.value = e.editor
            
            // editor.value.setContent(editor.value.getHTML().replace(new RegExp(`<span class=("|"([^"]*))variable("|([^"]*)").*?([^<]+)%%${variable.name}</span>`, "g"), variable.value));
            editor.value.isValid = () => checkRules()
            emit('update:editor', e.editor)
        }

        watch(valueInner, (newVal, oldVal) => {
            checkRules()
            // emit('change', oldVal)
            emit('update:editor', editor.value)
            emit('update:modelValue', newVal)
            
        })

        const checkRules = () => {
            isEmpty.value = editor.value.state.doc.textContent.trim().length === 0
            const text = editor.value.state.doc.textContent.trim()
            props.rules.map(r => {
                if (typeof r == "function") rules.value.push(r(text))
            })

            checker.value = rules.value.every(v => v === true)
            // editor.value.isValid = checker.value
            if (!checker.value) borderStyle.value = 'border: 2px solid #FF0000 !important;'
            else borderStyle.value = 'border: thin solid rgba(94, 86, 105, 0.14);'

            return checker.value
        }

        watch(
            () => props.modelValue,
            () => {
                valueInner.value = props.modelValue
                rules.value = []
            }
        )

        return {
            inputRef,
            onInit,
            borderStyle,
            checker,
            valueInner,
            icons: {
                mdiFormatUnderline,
                mdiFormatBold,
                mdiFormatStrikethrough,
                mdiFormatItalic,
                mdiAccountVoice,
                mdiMinus,
                mdiRedo,
                mdiUndo,
                mdiFormatListBulleted,
                mdiFormatListNumbered,
                mdiAt,
            },
            extensions: [
                Underline,
                Strike,
                Italic,
                Bold,
                Code,
                ListItem,
                BulletList,
                OrderedList,
                
                HorizontalRule,
                History,
            ],
            nativeExtensions: [
                new HardBreak(),
                new class extends Extension {
                keys() {
                    return {
                    Enter(state, dispatch, view) {
                        const { schema, doc, tr } = state
                        const typeName = state.tr.before.lastChild.type.name
                        let node = {}

                        switch (typeName){
                            case 'ordered_list':
                                node = schema.nodes.ordered_list
                                break;
                            case 'bullet_list':
                                node = schema.nodes.bullet_list
                                break;
                            default:
                                node = schema.nodes.hard_break
                        
                                const transaction = tr.replaceSelectionWith(node.create()).scrollIntoView()
                                dispatch(transaction)
                                return true
                        }
                        
                    }
                    }
                }
                }(),
                new Interviewer(),
                new Variable()
            ]
        }
        
    },
})
</script>

<style lang="scss">
  .tiptap-vuetify-editor__toolbar {
    .v-toolbar {

        display: flex;
        height: auto !important;
        padding: 5px;

      .v-toolbar__content {
        height: auto !important;
        flex-wrap: wrap;
        padding: 0;

      }
    }
    
    
    
  }
    
  interviewer.enq {
        color: #0000ff;
    }

    .variable {
        max-height: 40vh;
    border-radius: 0.25rem;
    background-color: #ffffff;
    box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
    overflow: auto;
    color: cornflowerblue;
}
</style>